<mat-form-field appearance="fill" [formGroup]="parentFormGroup">

    <mat-label>Kiképző</mat-label>
    <input type="text" matInput [formControlName]="controlName" [matAutocomplete]="auto">
    <mat-error>Kötelező mező</mat-error>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTrainerName">
      <mat-option *ngFor="let trainer of (filteredTrainers | async)" [value]="trainer">
        {{trainer.name}}
      </mat-option>
    </mat-autocomplete>

</mat-form-field>
