import { Component, Input } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { DocumentEnumType } from '../documents.component'

@Component({
  selector: 'app-document-select',
  templateUrl: './document-select.component.html',
  styleUrls: ['./document-select.component.scss'],
})
export class DocumentSelectComponent {
  constructor() {}

  @Input()
  documentTypes: DocumentEnumType[] = []

  @Input()
  selectControl: UntypedFormControl

  @Input()
  addEmptyElement = false
}
