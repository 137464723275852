import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage'
import { UntypedFormControl } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import moment from 'moment'
import { Observable, Subscription } from 'rxjs'
import { DocumentEntry, DocumentType } from 'src/domain/document'

export interface DeleteEvent {
  isNewDoc: boolean
  newDocId?: number
  doc?: DocumentEntryItem
}

export interface UploadEvent {
  newDocId: number
  file: File
  date: moment.Moment
  type: DocumentType
}

interface NewDocument {
  newDocId: number
  errorMessage?: string
  percentChange?: Observable<number>
  uploadTask: AngularFireUploadTask
}

export interface DocumentEntryItem extends DocumentEntry {
  docId: string
}

export type DocumentEnumType = { key: string; value: any }

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  constructor(
    private store: AngularFirestore,
    private auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private snack: MatSnackBar
  ) {}

  /**
   * Dog id or the trainer id
   */
  @Input()
  entityId: string

  /**
   * Firebase collection name
   */
  @Input()
  storageCollectionName: string

  @Input()
  documentTypes: DocumentEnumType[] = []

  @Input()
  get allDocuments(): DocumentEntryItem[] {
    return this._allDocuments
  }
  set allDocuments(docs: DocumentEntryItem[]) {
    this._allDocuments = docs
    this.filterDocuments(null)
  }
  private _allDocuments: DocumentEntryItem[] = []

  @Input()
  deleteDocumentOnUpload = false

  @Output()
  onRefresh = new EventEmitter<void>()

  private newDocumentId = 0

  documentTypeFilter = new UntypedFormControl(null)

  private sub = new Subscription()

  newDocuments: NewDocument[] = []

  filteredDocuments: DocumentEntryItem[] = []

  ngOnInit(): void {
    this.sub.add(this.documentTypeFilter.valueChanges.subscribe((key: string) => this.filterDocuments(key)))
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  trackByItem(index: number, item: NewDocument) {
    return item.newDocId
  }

  addNewDocument() {
    this.newDocumentId += 1
    this.newDocuments = [
      { newDocId: this.newDocumentId, errorMessage: undefined, percentChange: undefined, uploadTask: undefined },
      ...this.newDocuments,
    ]
  }

  private filterDocuments(typeKey: string) {
    if (typeKey) {
      this.filteredDocuments = this._allDocuments.filter((d) => d.type === typeKey)
    } else {
      this.filteredDocuments = this._allDocuments
    }
  }

  private getDocumentName(date: moment.Moment, type: DocumentType, filename: string) {
    const datePart = date.format('YYYY_MM_DD')
    const ext = filename.split('.').pop()
    return `${datePart}_${type}.${ext}`
  }

  private getDocumentFirebaseDoc(docId: string) {
    return this.store.collection(this.storageCollectionName).doc(this.entityId).collection('documents').doc<DocumentEntry>(docId)
  }

  private getDocumentStorageRef(docId: string) {
    return this.storage.ref(`${this.storageCollectionName}/${this.entityId}/documents/${docId}`)
  }

  async upload(event: UploadEvent) {
    if (this.entityId) {
      const docId = this.getDocumentName(event.date, event.type, event.file.name)
      const docRef = this.getDocumentFirebaseDoc(docId)
      const doc = await docRef.get().toPromise()
      if (doc.exists) {
        const humanName = this.documentTypes.filter((e) => e.key === event.type).map((e) => e.value)
        const errorMessage = `A ${event.date.format('YYYY.MM.DD')} dátummal már van dokumentum feltöltve a '${humanName}' típushoz.
        Törölje az előző dokumentumot ha újat szeretne feltölteni ezekkel a paraméterekkel.`
        this.syncNewDocuments(event.newDocId, { errorMessage })
        return
      }

      const storageRef = this.getDocumentStorageRef(docId)
      const uploadTask = storageRef.put(event.file)
      this.syncNewDocuments(event.newDocId, { percentChange: uploadTask.percentageChanges(), uploadTask, errorMessage: undefined })

      try {
        await uploadTask.then()
        const downloadUrl = await storageRef.getDownloadURL().toPromise()
        docRef.set({
          documentDate: event.date.toDate(),
          downloadUrl,
          fileType: event.file.type,
          type: event.type,
          createdAt: new Date(),
          createdBy: (await this.auth.currentUser).email,
        })
        this.newDocuments = this.newDocuments.filter((d) => d.newDocId !== event.newDocId)
        this.onRefresh.emit()

        if (this.deleteDocumentOnUpload) {
          // always delete the missing documentation on new upload
          const missingDocId = `${this.entityId}_${event.type}`
          await this.store.collection('expiring-documents').doc(missingDocId).delete()
        }
      } catch (e) {
        if (e.code === 'storage/canceled') {
          console.log('User cancelled upload')
          this.filterOutNewDoc(event.newDocId)
          this.snack.open(`Feltöltés megszakítva ${event.date.format('YYYY.MM.DD')} - ${DocumentType[event.type]}`, 'Ok', {
            duration: 5000,
          })
        } else {
          console.error('Upload failed', e)
          this.syncNewDocuments(event.newDocId, { errorMessage: 'Nem sikerült a feltöltés valami miatt.' })
        }
      }
    }
  }

  private filterOutNewDoc(newDocId: number) {
    this.newDocuments = this.newDocuments.filter((d) => d.newDocId !== newDocId)
  }

  private syncNewDocuments(newDocId: number, newDoc: Partial<NewDocument>) {
    // do the maneuver to make change detection work
    const updatedDocs = [...this.newDocuments]
    const spliceIdx = this.newDocuments.findIndex((d) => d.newDocId === newDocId)
    const prevDoc = this.newDocuments[spliceIdx]
    updatedDocs.splice(spliceIdx, 1, { ...prevDoc, ...newDoc })
    this.newDocuments = updatedDocs
  }

  async delete(event: DeleteEvent) {
    console.log('Delete event', event)
    if (event.isNewDoc) {
      this.newDocuments = this.newDocuments.filter((i) => {
        if (i.uploadTask?.cancel()) {
          console.log(i, 'task cancelled')
        }
        return i.newDocId !== event.newDocId
      })
    } else {
      const { doc } = event
      const storageRef = this.getDocumentStorageRef(doc.docId)
      const storeRef = this.getDocumentFirebaseDoc(doc.docId)

      try {
        await storageRef.delete().toPromise()
        await storeRef.delete()
        this.onRefresh.emit()
        this.snack.open('Dokumentum sikeresen törölve', 'Ok', { duration: 2000 })
      } catch (e) {
        console.error(e)
        this.snack.open('Valami baj történt a dokumentum törlése közben', 'Ajaj')
      }
    }
  }
}
