export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBbUUjgMc--7Tdr12k6eexTx2kfhyGsu_Q',
    authDomain: 'kutyavalegymosolyert-admin.firebaseapp.com',
    databaseURL: 'https://kutyavalegymosolyert-admin.firebaseio.com',
    projectId: 'kutyavalegymosolyert-admin',
    storageBucket: 'kutyavalegymosolyert-admin.appspot.com',
    messagingSenderId: '823532617441',
    appId: '1:823532617441:web:7180db55165dc0823b81a0',
    measurementId: 'G-XRNFXCG6MF'
  }
};
