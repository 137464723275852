import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SharedModule } from '../shared.module'
import { DocumentEntryComponent } from './documents/document-entry/document-entry.component'
import { DocumentSelectComponent } from './documents/document-select/document-select.component'
import { DocumentsComponent } from './documents/documents.component'
import { DogListComponent } from './dog-list/dog-list.component'
import { TrainerSelectComponent } from './trainer-select/trainer-select.component'

const components = [DogListComponent, TrainerSelectComponent, DocumentsComponent, DocumentSelectComponent, DocumentEntryComponent]

@NgModule({
  declarations: components,
  exports: [DocumentsComponent, TrainerSelectComponent, DogListComponent],
  imports: [SharedModule, RouterModule],
})
export class ComponentsModule {}
