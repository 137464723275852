import { Component, OnInit } from '@angular/core'
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore'
import { Dog } from 'src/domain/dog'
import { ListDog } from '../components/dog-list/dog-list.component'
import { mapFirebaseDog } from '../service/mapper/dogMapper'

@Component({
  selector: 'app-active-dogs',
  styleUrls: ['./active-dogs.component.scss'],
  templateUrl: './active-dogs.component.html',
})
export class ActiveDogsComponent implements OnInit {
  constructor(private store: AngularFirestore) {}

  fullDogList: ListDog[] = []

  ngOnInit(): void {
    this.store
      .collection<Dog>('dogs')
      .get()
      .subscribe((snapshot: QuerySnapshot<Dog>) => {
        this.fullDogList = snapshot.docs
          .map(
            (doc) =>
              ({
                ...mapFirebaseDog(doc.data()),
                docId: doc.id,
              } as ListDog)
          )
          .filter((d) => !d.deletedAt && !d.retiredAt)
      })
  }
}
