import { Component, OnInit } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import moment from 'moment'
import { filter, map, switchMap } from 'rxjs/operators'
import { AssistanceDogType, asssistanceDogType, Dog, DogSex, dogSexArray, Organization, Trainer } from 'src/domain/dog'
import { mapFirebaseDog } from '../service/mapper/dogMapper'

interface DogForm {
  name: string
  birthDate: moment.Moment
  breed: string
  dogSex: string
  assistanceTypes: AssistanceDogType[]
  organizations: Organization[]
  chipNumber: string
  trainer: Trainer
  owner: {
    name: string
    address: string
    phone?: string
    email?: string
  }
}

const notEmptyList: ValidatorFn = (c: AbstractControl): ValidationErrors => (c.value?.length > 0 ? null : { invalid: 'Üres lista' })

@Component({
  styleUrls: ['./new-dog.component.scss'],
  templateUrl: './new-dog.component.html',
})
export class NewDogComponent implements OnInit {
  dogGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    birthDate: new UntypedFormControl(null, Validators.required),
    breed: new UntypedFormControl(null, Validators.required),
    dogSex: new UntypedFormControl(null, Validators.required),
    assistanceTypes: new UntypedFormControl([], [Validators.required, notEmptyList]),
    organizations: new UntypedFormControl([], [Validators.required, notEmptyList]),
    chipNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^\d{15}$/)]),
    trainer: new UntypedFormControl(null, Validators.required),
    owner: new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      address: new UntypedFormControl(null),
      phone: new UntypedFormControl(null),
      email: new UntypedFormControl(null, Validators.email),
    }),
  })

  singleAssistanceType = new UntypedFormControl(null)

  originalDogId: string | undefined
  originalDog: Dog | undefined
  dogSexs = dogSexArray
  assistanceTypes = asssistanceDogType

  title = 'Új kutya hozzáadása'
  maxDate = new Date()
  saveError = ''
  isSaving = false
  isDogRetired = false

  constructor(
    private storage: AngularFirestore,
    private auth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        map((params) => params.get('dogId')),
        filter((x) => !!x),
        switchMap((dogId) => {
          return this.storage.collection<Dog>('dogs').doc<Dog>(dogId).get()
        })
      )
      .subscribe({
        next: (dogDoc) => {
          if (dogDoc.exists) {
            this.originalDogId = dogDoc.id
            this.originalDog = mapFirebaseDog(dogDoc.data())
            this.resetDog()
          } else {
            console.log('Doc document not exists', dogDoc)
          }
        },
      })

    this.singleAssistanceType.valueChanges.subscribe((v) => {
      if (v) {
        const control = this.dogGroup.get('assistanceTypes')
        const prevValue = control.value as AssistanceDogType[]
        if (!prevValue.includes(v)) {
          control.setValue([v, ...prevValue])
        }
        this.singleAssistanceType.reset()
      }
    })
  }

  removeAssistanceTypeFromList(id: string) {
    const control = this.dogGroup.get('assistanceTypes')
    const prevValue = control.value as AssistanceDogType[]
    const filtered = prevValue.filter((i) => i !== id)
    control.setValue(filtered)
  }

  getAssistanceTypeName(id: string) {
    return AssistanceDogType[id]
  }

  organizationCheckBox(orgStr: string, change: MatCheckboxChange) {
    const org = Organization[orgStr]
    if (org) {
      const c = this.dogGroup.get('organizations')
      console.log('old', c.value)
      c.setValue(change.checked ? [org, ...c.value] : c.value.filter((x: Organization) => x !== org))
      console.log('new', c.value)
    } else {
      console.error('No organization as for checkbox', orgStr)
    }
  }

  resetDog() {
    if (this.originalDog) {
      const action = this.originalDog.retiredAt ? 'Nyugdíjazott' : 'Módosítás'
      this.title = `${action}: ${this.originalDog.name}`
      this.isDogRetired = !!this.originalDog.retiredAt

      this.dogGroup.setValue({
        name: this.originalDog.name || '',
        birthDate: moment(this.originalDog.birthDate),
        breed: this.originalDog.breed || '',
        dogSex: this.originalDog.dogSex || '',
        assistanceTypes: this.originalDog.assistanceTypes || [],
        organizations: this.originalDog.organization || [],
        chipNumber: this.originalDog.chipNumber || '',
        trainer: this.originalDog.trainer,
        owner: {
          name: this.originalDog.owner.name,
          address: this.originalDog.owner.address || '',
          phone: this.originalDog.owner.phone || '',
          email: this.originalDog.owner.email || '',
        },
      } as DogForm)
      if (this.isDogRetired) {
        this.dogGroup.disable()
      }
    }
  }

  confirmResetDog() {
    if (confirm('Biztosan törli a módosításokat?')) {
      this.resetDog()
    }
  }

  async confirmRetireDog() {
    if (this.originalDogId && !this.isDogRetired && confirm(`Biztosan szeretné nyugdíjazni ${this.originalDog.name}-t?`)) {
      try {
        const email = (await this.auth.currentUser).email

        console.log('Retiring dog ', this.originalDogId, this.originalDog.name)

        await this.storage
          .collection('dogs')
          .doc(this.originalDogId)
          .set({
            ...this.originalDog,
            retiredAt: new Date(),
            retiredBy: email,
          } as Dog)
        await this.removeMissingDocEntries()
        this.router.navigate(['active-dogs'])
        this.snackBar.open(`${this.originalDog.name} sikeresen nyugdíjazva.`, 'Ok')
      } catch {
        this.snackBar.open(`${this.originalDog.name} nem sikerült nyugdíjazni.`, 'Ajaj')
      }
    }
  }

  async confirmReInstateDog() {
    if (this.isDogRetired && confirm(`Biztosan szeretné újra munkába helyezni ${this.originalDog.name}-t?`)) {
      try {
        console.log('Reinstate dog ', this.originalDogId, this.originalDog.name)

        // firestore doesn't like undefined delete the fields
        const data = { ...this.originalDog }
        delete data.retiredAt
        delete data.retiredBy

        await this.storage.collection('dogs').doc(this.originalDogId).set(data)
        this.router.navigate(['active-dogs'])
        this.snackBar.open(`${this.originalDog.name} sikeresen újra munkába állítva.`, 'Ok')
      } catch (e) {
        console.error(e)
        this.snackBar.open(`${this.originalDog.name} nem sikerült munkába állítani.`, 'Ajaj')
      }
    }
  }

  async submit() {
    console.log(this.dogGroup.value)

    if (this.dogGroup.invalid || (this.originalDog && this.originalDog.retiredAt)) {
      return
    }
    this.isSaving = true

    const email = (await this.auth.currentUser).email
    const value = this.dogGroup.value as DogForm

    const dogSex = DogSex[value.dogSex]
    if (!dogSex) {
      throw { code: 0, message: `Nem létező kutya nem: ${value.dogSex} ` }
    }

    const newDog = {
      name: value.name,
      birthDate: value.birthDate.toDate(),
      breed: value.breed,
      dogSex: value.dogSex,
      assistanceTypes: value.assistanceTypes,
      organization: value.organizations,
      chipNumber: value.chipNumber,
      owner: value.owner,
      trainer: value.trainer,
      createdAt: new Date(),
      createdBy: email,
    } as Dog

    console.log('Saving new dog', newDog)
    try {
      if (this.originalDogId) {
        await this.storage.collection<Dog>('dogs').doc(this.originalDogId).update(newDog)
        this.snackBar.open('Sikeres frissítés', 'Ok')
      } else {
        const existingDogWidthChipNumber = await this.checkUniqueChipNumber(newDog.chipNumber)
        if (existingDogWidthChipNumber) {
          alert(
            `Mentés sikertelen. A chip azonosító nem egyedi. ` +
              `Létező chipszám: ${existingDogWidthChipNumber.name} - (${existingDogWidthChipNumber.owner.name})`
          )
          return
        }

        await this.storage.collection<Dog>('dogs').add(newDog)
        this.snackBar.open('Sikeres mentés', 'Ok')
        this.router.navigate(['/acive-dogs'])
      }
    } catch (e) {
      console.error(e)
      this.saveError = `Sikertelen mentés: ${e.code} ${e.message}`
    } finally {
      this.isSaving = false
    }
  }

  private async checkUniqueChipNumber(chipNumber: string) {
    const result = (await this.storage
      .collection<Dog>('dogs', (ref) => ref.where('chipNumber', '==', chipNumber).limit(1))
      .get()
      .toPromise()) as QuerySnapshot<Dog>
    return mapFirebaseDog(result.docs[0]?.data())
  }

  async removeDog() {
    if (this.originalDogId && confirm('Biztos ki szeretné törölni a kutyát? A feltöltött dokumentumok megmaradnak a tárhelyen')) {
      this.isSaving = true
      try {
        const email = (await this.auth.currentUser).email

        const deleteDog = {
          ...this.originalDog,
          deletedAt: new Date(),
          deletedBy: email,
        } as Dog

        await this.storage.collection('dogs').doc(this.originalDogId).set(deleteDog)
        await this.removeMissingDocEntries()
        this.snackBar.open('Sikeres törlés', 'Ok', { duration: 2000 })
        this.router.navigate(['acive-dogs'])
      } catch (e) {
        console.error(e)
        this.snackBar.open('Sikertelen törlés', 'Ajaj')
      } finally {
        this.isSaving = false
      }
    }
  }

  private async removeMissingDocEntries() {
    // always delete the missing documentation on new upload
    for (const docType of Object.keys(AssistanceDogType)) {
      const missingDocId = `${this.originalDogId}_${docType}`
      console.log('Deleting expiry doc type', missingDocId)
      await this.storage.collection('expiring-documents').doc(missingDocId).delete()
    }
  }
}
