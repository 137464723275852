<mat-sidenav-container #sidenav>
  <mat-sidenav [opened]="sideNavOpen" mode="side">
    <button id="close-sidenav" mat-icon-button (click)="sideNavOpen = false">Bezár <mat-icon>menu</mat-icon></button>
    <div id="current-user">
      <div>
        Szia <strong>{{ currentUserName }}</strong>
        <button mat-icon-button (click)="logout()">
          <mat-icon style="font-size: 20px">exit_to_app</mat-icon>
        </button>
      </div>
    </div>
    <nav>
      <button routerLinkActive="active" [routerLink]="['/new-dog']" mat-button>Új kutya</button>
      <button routerLinkActive="active" [routerLink]="['/active-dogs']" mat-button>Kutya lista</button>
      <button routerLinkActive="active" [routerLink]="['/trainers']" mat-button>Kiképzők</button>
      <button routerLinkActive="active" [routerLink]="['/training-type']" mat-button>Gyakorlatok</button>
      <button routerLinkActive="active" [routerLink]="['/retired-dogs']" mat-button>Nyugdíjas kutyák</button>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div id="container">
      <div id="contant-quick-nav">
        <button *ngIf="showBackToList" mat-icon-button [routerLink]="['/active-dogs']" queryParamsHandling="preserve">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button *ngIf="!sideNavOpen" mat-icon-button (click)="sideNavOpen = true">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
