import { Component, OnDestroy, OnInit } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { QuerySnapshot } from '@angular/fire/compat/firestore/interfaces'
import { ActivatedRoute } from '@angular/router'
import { of, throwError } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { DocumentEntry, documentTypesArray } from 'src/domain/document'
import { DocumentEntryItem } from '../components/documents/documents.component'

@Component({
  templateUrl: './dog-documents.component.html',
  styleUrls: ['./dog-documents.component.scss'],
})
export class DogDocumentsComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private store: AngularFirestore) {}

  dogId: string
  dogName = ''

  allDocuments: DocumentEntryItem[] = []
  // we don't allow new MATESZE docs to be uploaded but need to list older one
  dogDocumenTypes = documentTypesArray.filter(v => !v.key.startsWith("MATESZE"))

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        flatMap((param) => {
          this.dogId = param.get('dogId')
          this.dogName = param.get('dogName')

          if (this.dogId) {
            return of(this.dogId)
          } else {
            return throwError('Nincs kutya azonosító megadva')
          }
        })
      )
      .subscribe(
        () => this.refreshDocuments(),
        (e) => console.error(e)
      )
  }

  ngOnDestroy(): void {}

  refreshDocuments() {
    this.store
      .collection('dogs')
      .doc(this.dogId)
      .collection('documents', (r) => r.orderBy('documentDate', 'desc'))
      .get()
      .subscribe((data: QuerySnapshot<DocumentEntry>) => {
        this.allDocuments = data.docs.map((d) => {
          const docData = d.data()
          return {
            docId: d.id,
            downloadUrl: docData.downloadUrl,
            type: docData.type,
            fileType: docData.fileType || 'application/pdf',
            createdBy: docData.createdBy,
            createdAt: (docData.createdAt as any).toDate(),
            documentDate: (docData.documentDate as any).toDate(),
          }
        })
      })
  }
}
