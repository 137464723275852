<h1>{{dogName}} - etetési napló</h1>

<mat-tab-group>
  <mat-tab label="Etetés">
    <app-food [foods]="foods" (delete)="removeFood($event)" (save)="newFoodEvent($event)"></app-food>
  </mat-tab>
  <mat-tab label="Gyógyszerelés">
    <app-medicine [medicines]="medicines" (delete)="removeMedicine($event)" (save)="newMedicineEvent($event)">
    </app-medicine>
  </mat-tab>
  <mat-tab label="Grafikon">
    <ng-template matTabContent>
      <app-chart [foods]="foods"></app-chart>
    </ng-template>
  </mat-tab>
</mat-tab-group>
