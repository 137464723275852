import { Dog } from 'src/domain/dog'

export function mapFirebaseDog(data: Dog): Dog {
  if (data) {
    return {
      ...data,
      birthDate: (data.birthDate as any).toDate(),
      retiredAt: data.retiredAt ? (data.retiredAt as any).toDate() : null,
      deletedAt: data.deletedAt ? (data.deletedAt as any).toDate() : null,
      createdAt: (data.createdAt as any).toDate(),
    }
  } else {
    return null
  }
}
