import { registerLocaleData } from '@angular/common'
import hunLocal from '@angular/common/locales/hu'
import { LOCALE_ID, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import 'moment'
import { environment } from 'src/environments/environment'
import { ActiveDogsComponent } from './active-dogs/active-dogs.component'
import { MissingDocumentsComponent } from './active-dogs/missing-documents/missing-documents.component'
import { AppComponent } from './app.component'
import { ComponentsModule } from './components/components.module'
import { DogDocumentsComponent } from './dog-documents/dog-documents.component'
// load once
import './highcharts.module'
import { IdCardComponent } from './idcard/idcard.component'
import { ImageUploadComponent } from './idcard/image-upload/image-upload.component'
import { LoginComponent } from './login/login.component'
import { NavComponent } from './nav/nav.component'
import { NewDogComponent } from './newdog/new-dog.component'
import { ProvisionModule } from './provision/provision.module'
import { RetiredDogsComponent } from './retired-dogs/retired-dogs.component'
import { AppRoutingModule } from './routing.module'
import { TrainerDocumentsComponent } from './settings/trainers/trainer-document/trainer-document.component'
import { TrainerEditComponent } from './settings/trainers/trainer-edit/trainer-edit.component'
import { TrainerListComponent } from './settings/trainers/trainer-list/trainer-list.component'
import { TrainingTypeComponent } from './settings/training-type/training-type.component'
import { SharedModule } from './shared.module'
import { TrainingModule } from './training/training.module'

registerLocaleData(hunLocal)

const dateFormat: MatDateFormats = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    NewDogComponent,
    DogDocumentsComponent,
    MissingDocumentsComponent,
    TrainingTypeComponent,
    RetiredDogsComponent,
    ActiveDogsComponent,
    TrainerEditComponent,
    TrainerListComponent,
    TrainerDocumentsComponent,
    ImageUploadComponent,
    IdCardComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    TrainingModule,
    ProvisionModule,
    ComponentsModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    { provide: LOCALE_ID, useValue: 'hu' },
    { provide: MAT_DATE_FORMATS, useValue: dateFormat },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
