<h1>{{ trainer?.name }} módosítása</h1>
<div>
  <form [formGroup]="trainerForm" (ngSubmit)="saveTrainer()">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Kiképző neve</mat-label>
        <input matInput formControlName="name" #trainerName placeholder="Kiképző János" />
        <mat-error>Kötelező mező</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>Telefonszám</mat-label>
        <input matInput formControlName="phone" type="tel" placeholder="+3630 123-4567" />
        <mat-error *ngIf="trainerForm.get('phone').hasError('required')">Kötelező mező</mat-error>
        <mat-error *ngIf="trainerForm.get('phone').hasError('invalid')">Hibás telefonszám formátum</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" placeholder="kikepzo@kema.hu" />
        <mat-error>Hibás email formátum</mat-error>
      </mat-form-field>

      <div>
        <button [disabled]="trainerForm.invalid || trainerForm.pristine" mat-raised-button color="primary" type="submit">
          Kiképző mentése
        </button>
        <button id="delete-btn" [disabled]="trainerForm.invalid || isSaving" mat-raised-button color="warn" type="reset" (click)="removeTrainer()">
          Törlés
        </button>
      </div>
    </div>
  </form>

  <p>Utoljára frissítve {{ trainer?.createdAt | date: 'medium' }} által {{ trainer?.createdBy }}</p>
</div>
