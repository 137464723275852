import { Component, OnInit } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { firstValueFrom, of, throwError } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { trainerMapper } from 'src/app/service/mapper/trainerMapper'
import { hunPhoneNumberValidator } from 'src/app/service/phone-number-validator'
import { TrainersService } from 'src/app/service/trainer.service'
import { Trainer } from 'src/domain/dog'

@Component({
  templateUrl: './trainer-edit.component.html',
  styleUrls: ['./trainer-edit.component.scss'],
})
export class TrainerEditComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private trainerService: TrainersService, private auth: AngularFireAuth, private snack: MatSnackBar) { }

  trainerId: string
  trainer: Trainer
  isSaving = false
  trainerForm = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.required),
    phone: new FormControl(null, [Validators.required, hunPhoneNumberValidator]),
  })

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        mergeMap((param) => {
          this.trainerId = param.get('trainerId')
          console.log('param', param)
          if (this.trainerId) {
            return of(this.trainerId)
          } else {
            return throwError(() => new Error('Nincs kiképző azonosító megadva'))
          }
        })
      )
      .subscribe({
        next: () => this.refreshTrainer(),
        error: (e) => console.error(e)
      })
  }

  private async refreshTrainer() {
    const data = await firstValueFrom(this.trainerService.getTrainerDoc(this.trainerId).get())
    console.log(data)
    this.trainer = trainerMapper(data.data())
    this.trainerForm.reset(this.trainer)
    this.trainerForm.get('email').disable()
  }

  async saveTrainer() {
    const form = this.trainerForm.value

    const createdBy = (await this.auth.currentUser).email

    const newTrainer: Trainer = {
      ...this.trainer,
      name: form.name,
      phone: form.phone,
      createdAt: new Date(),
      createdBy,
    }

    try {
      await this.trainerService.getTrainerDoc(this.trainerId).set(newTrainer)
      this.snack.open('Sikeres mentés', 'Oké', { duration: 2000 })
      this.refreshTrainer()
    } catch (e) {
      console.error(e)
      this.snack.open('Hiba történt a mentés során')
    }
  }

  async removeTrainer() {
    const { name } = this.trainerForm.value
    try {
      if (name != null && confirm(`Biztosan szeretnéd eltüntetni ${name}?`)) {
        await this.trainerService.removeTrainer(this.trainerId)
        this.snack.open(`${name} eltüntetve`, 'Oké', { duration: 2000 })
        this.router.navigate(['/trainers'])
      }
    } finally {
      this.isSaving = false
    }
  }
}
