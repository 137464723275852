import { Component, OnInit } from '@angular/core'
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore'
import { ActivatedRoute } from '@angular/router'
import { of, throwError } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { DocumentEnumType } from 'src/app/components/documents/documents.component'
import { DocumentEntry, trainerDocumentTypesArray } from 'src/domain/document'

@Component({
  templateUrl: './trainer-document.component.html',
  styleUrls: ['./trainer-document.component.scss'],
})
export class TrainerDocumentsComponent implements OnInit {
  documentTypes: DocumentEnumType[] = trainerDocumentTypesArray

  trainerId: string
  trainerName: string
  allDocuments: DocumentEntry[]

  constructor(private route: ActivatedRoute, private store: AngularFirestore) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        flatMap((param) => {
          this.trainerId = param.get('trainerId')
          this.trainerName = param.get('trainerName')

          if (this.trainerId) {
            return of(this.trainerId)
          } else {
            return throwError('Nincs kiképző azonosító megadva')
          }
        })
      )
      .subscribe(
        () => this.refreshDocuments(),
        (e) => console.error(e)
      )
  }

  refreshDocuments() {
    this.store
      .collection('trainers')
      .doc(this.trainerId)
      .collection('documents', (r) => r.orderBy('documentDate', 'desc'))
      .get()
      .subscribe((data: QuerySnapshot<DocumentEntry>) => {
        this.allDocuments = data.docs.map((d) => {
          const docData = d.data()
          return {
            docId: d.id,
            downloadUrl: docData.downloadUrl,
            type: docData.type,
            fileType: docData.fileType || 'application/pdf',
            createdBy: docData.createdBy,
            createdAt: (docData.createdAt as any).toDate(),
            documentDate: (docData.documentDate as any).toDate(),
          }
        })
      })
  }
}
