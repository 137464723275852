import { NgModule } from '@angular/core'
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard'
import { RouterModule, Routes } from '@angular/router'
import { ActiveDogsComponent } from './active-dogs/active-dogs.component'
import { DogDocumentsComponent } from './dog-documents/dog-documents.component'
import { IdCardComponent } from './idcard/idcard.component'
import { LoginComponent } from './login/login.component'
import { NewDogComponent } from './newdog/new-dog.component'
import { ProvisionComponent } from './provision/provision.component'
import { RetiredDogsComponent } from './retired-dogs/retired-dogs.component'
import { TrainerDocumentsComponent } from './settings/trainers/trainer-document/trainer-document.component'
import { TrainerEditComponent } from './settings/trainers/trainer-edit/trainer-edit.component'
import { TrainerListComponent } from './settings/trainers/trainer-list/trainer-list.component'
import { TrainingTypeComponent } from './settings/training-type/training-type.component'
import { TrainingComponent } from './training/training.component'

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login'])

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'new-dog', component: NewDogComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'active-dogs', component: ActiveDogsComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'retired-dogs', component: RetiredDogsComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'dog/:dogId/edit', component: NewDogComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'dog/:dogId/idcard', component: IdCardComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'dog/training', component: TrainingComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'dog/documents', component: DogDocumentsComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'dog/provision', component: ProvisionComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'trainers', component: TrainerListComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'trainer/edit', component: TrainerEditComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'trainer/documents', component: TrainerDocumentsComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: 'training-type', component: TrainingTypeComponent, ...canActivate(redirectUnauthorizedToLogin) },
  { path: '**', redirectTo: 'active-dogs' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
