import { Component, OnInit } from '@angular/core'
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore'
import { Dog } from 'src/domain/dog'
import { ListDog } from '../components/dog-list/dog-list.component'
import { mapFirebaseDog } from '../service/mapper/dogMapper'

@Component({
  selector: 'app-retired-dogs',
  templateUrl: './retired-dogs.component.html',
  styleUrls: ['./retired-dogs.component.scss'],
})
export class RetiredDogsComponent implements OnInit {
  retiredDogs: ListDog[] = []

  constructor(private store: AngularFirestore) {}

  ngOnInit(): void {
    this.store
      .collection<Dog>('dogs')
      .get()
      .subscribe((snapshot: QuerySnapshot<Dog>) => {
        this.retiredDogs = snapshot.docs
          .map(
            (doc) =>
              ({
                ...mapFirebaseDog(doc.data()),
                docId: doc.id,
              } as ListDog)
          )
          .filter((d) => !d.deletedAt && d.retiredAt)
      })
  }
}
