<h1>{{ dog?.name }} igazolvány készítő</h1>

<mat-button-toggle-group name="idcardType" (change)="idCardTypeChange($event)">
  <mat-button-toggle value="facility">Terápiás kutya</mat-button-toggle>
  <mat-button-toggle value="service">Segítő kutya</mat-button-toggle>
  <mat-button-toggle value="service_training">Segítő tanuló kutya</mat-button-toggle>
</mat-button-toggle-group>

<div #container></div>

<div id="profile-container">
  <div>
    <h3>Kutya profil kép</h3>
    <app-image-upload width="400" height="400" (onImage)="dogImageSelected($event)"></app-image-upload>
  </div>
  <div>
    <h3>Kiképző</h3>
    <mat-form-field appearance="fill">
      <mat-label>Neve</mat-label>
      <input matInput [formControl]="trainerNameControl" />
      <mat-error>Kötelező mező</mat-error>
    </mat-form-field>
  </div>
  <div>
    <h3>Egyéb</h3>
    <div id="misc">
      <mat-form-field appearance="fill">
        <mat-label>Sorszám</mat-label>
        <input matInput [formControl]="idCardNumberControl" />
        <mat-error>Kötelező mező</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Érvényesség vége</mat-label>
        <input matInput [formControl]="idCardExpiryControl" />
        <mat-error>éééé.hh.nn formátum (pl. 2022.10.08)</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
