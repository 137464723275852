import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlHun extends MatPaginatorIntl {
  itemsPerPageLabel = 'Oldalanként'
  nextPageLabel = 'Következő'
  previousPageLabel = 'Előző'

  getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return `0 / 0-ból`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    const displayStartIndex = startIndex + 1
    return `${displayStartIndex} - ${endIndex} / ${length}`
  };

}
