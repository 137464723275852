<h1>Kiképzők</h1>

<div id="header">
  <div>
    <button mat-raised-button color="primary" (click)="toggleTrainerForm()">Új kiképző</button>
  </div>

  <div>
    <form *ngIf="showTrainerForm" [formGroup]="trainerForm" (ngSubmit)="saveTrainer()">
      <mat-form-field appearance="fill">
        <mat-label>Kiképző neve</mat-label>
        <input matInput formControlName="name" #trainerName placeholder="Kiképző János" />
        <mat-error>Kötelező mező</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Telefonszám</mat-label>
        <input matInput formControlName="phone" type="tel" placeholder="+3630 123-4567" />
        <mat-error *ngIf="trainerForm.get('phone').hasError('required')">Kötelező mező</mat-error>
        <mat-error *ngIf="trainerForm.get('phone').hasError('invalid')">Hibás telefonszám formátum</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" placeholder="kikepzo@kema.hu" />
        <mat-error>Hibás email formátum</mat-error>
      </mat-form-field>

      <div>
        <button [disabled]="trainerForm.invalid" mat-raised-button color="primary" type="submit">Új kiképző mentése</button>
      </div>
    </form>
  </div>

  <mat-form-field id="search-field">
    <mat-label>Keresés</mat-label>
    <input matInput [formControl]="searchControl" placeholder="Kiképző neve" />
  </mat-form-field>
</div>

<div>
  <table mat-table [dataSource]="trainerList" matSort>
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <span class="table-index">{{ i + 1 }}.</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kiképző neve</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
      <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          [routerLink]="['/trainer', 'documents', { trainerId: element.trainerId, trainerName: element.name }]"
          matTooltip="Dokumentumok/iratok"
          queryParamsHandling="preserve"
        >
          <mat-icon>folder</mat-icon>
        </button>
        <button
          mat-icon-button
          [routerLink]="['/trainer', 'edit', { trainerId: element.trainerId }]"
          matTooltip="Szerkesztés"
          queryParamsHandling="preserve"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nincs találat</td>
    </tr>
  </table>
</div>
