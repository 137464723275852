import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { AssistanceDogType, Dog, asssistanceDogType } from 'src/domain/dog'

export class ListDog extends Dog {
  docId: string
}

type DogListPredicate = (key: string, dog: ListDog) => boolean
interface DogListFilter {
  control: UntypedFormControl
  key?: string
  predicate: DogListPredicate
}

const fullColumns = ['index', 'name', 'birthDate', 'assistanceTypes', 'owner', 'phone', 'trainer', 'actions']
const shortColumns = ['name', 'owner', 'actions']

@Component({
  selector: 'app-doglist',
  styleUrls: ['./dog-list.component.scss'],
  templateUrl: './dog-list.component.html',
})
export class DogListComponent implements OnInit, OnDestroy, OnChanges {
  displayedColumns = fullColumns
  dogList: MatTableDataSource<ListDog> = new MatTableDataSource<ListDog>()

  // search controls
  dogNameFilter = new UntypedFormControl(null)
  assistanceTypeFilter = new UntypedFormControl(null)
  compositeSearchFilter = new UntypedFormControl(null)

  assistanceTypes = asssistanceDogType

  private currentFilters: { [key: string]: DogListFilter } = {}

  private sub = new Subscription()

  @ViewChild(MatSort, { static: true })
  sort: MatSort

  @Input()
  fullDogList: ListDog[]

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    const dogs = changes['fullDogList']
    if (dogs.previousValue != dogs.currentValue) {
      this.dogList.data = dogs.currentValue
      this.dogNameFilter.enable()
      this.assistanceTypeFilter.enable()
      this.compositeSearchFilter.enable()
    }
  }

  ngOnInit(): void {
    this.displayedColumns = window.innerWidth < 400 ? shortColumns : fullColumns
    this.compositeSearchFilter.disable()
    this.assistanceTypeFilter.disable()
    this.dogNameFilter.disable()

    this.dogList.sort = this.sort
    this.sort.sort({ id: 'name', start: 'asc', disableClear: false })

    // dog name
    this.onFilterChange(this.dogNameFilter, 'name', (key, d) => {
      return d.name.toLocaleLowerCase().includes(key.toLocaleLowerCase())
    })

    // dog type
    this.onFilterChange(
      this.assistanceTypeFilter,
      'type',
      (key, d) => {
        return d.assistanceTypes.includes(key as AssistanceDogType)
      },
      false
    )

    // composite search
    this.onFilterChange(this.compositeSearchFilter, 'all', (key, d) => {
      return JSON.stringify(d).toLocaleLowerCase().includes(key.toLocaleLowerCase())
    })

    // always the last one of the composite filters
    this.setDogListFilterPredicate()
  }

  private onFilterChange(control: UntypedFormControl, queryName: string, predicate: DogListPredicate, toLowerCase = true) {
    const initialNeedle = this.route.snapshot.queryParamMap.get(queryName)
    control.setValue(initialNeedle)

    this.currentFilters[queryName] = { predicate, control }

    const s = control.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((key: string) => {
      this.currentFilters[queryName] = {
        key: toLowerCase && key ? key.toLocaleLowerCase() : key,
        predicate,
        control,
      }
      // only make the filter change to make filtering happen
      this.dogList.filter = Object.values(this.currentFilters)
        .filter((f) => f.key)
        .map((f) => f.key)
        .join('')
      this.router.navigate([], {
        queryParams: { [queryName]: key },
        queryParamsHandling: 'merge',
      })
    })

    this.sub.add(s)
  }

  private setDogListFilterPredicate() {
    const aggregate = (dog: ListDog) => {
      const filters = Object.values(this.currentFilters)
      let result = true
      for (const filter of filters) {
        const { key, predicate } = filter
        if (key) {
          result = result && predicate(key, dog)
          // short circuit
          if (!result) {
            return false
          }
        }
      }
      return result
    }
    this.dogList.filterPredicate = aggregate
  }

  hasAnySearchFieldsSet() {
    return Object.values(this.currentFilters).find((f) => f.key)
  }

  clearSearchFields() {
    Object.values(this.currentFilters).forEach((f) => f.control.reset(undefined))
  }

  getAllAssistanceMultilineName(dog: ListDog) {
    return dog.assistanceTypes.map((id) => AssistanceDogType[id]).join('\n')
  }

  isDogStudent(dog: ListDog) {
    return !(dog.trainingMileStones && Object.values(dog.trainingMileStones).filter((tm) => tm.examDate).length > 0)
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
