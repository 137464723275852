import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { UntypedFormControl, Validators } from '@angular/forms'
import { MatButtonToggleChange } from '@angular/material/button-toggle'
import { ActivatedRoute } from '@angular/router'
import moment from 'moment'
import * as PIXI from 'pixi.js'
import { filter, map, switchMap } from 'rxjs/operators'
import { Dog, DogSex } from 'src/domain/dog'
import { mapFirebaseDog } from '../service/mapper/dogMapper'

const EXPIRY_TIME_FORMAT = 'yyyy.MM.DD.'

const IDCARD_META = {
  facility: {
    size: [1600, 1036],
    backgroundUrl: 'assets/idcards/facility_dog.png',
  },
  service: {
    size: [1600, 1036],
    backgroundUrl: 'assets/idcards/service_dog.png',
  },
  service_training: {
    size: [1600, 1036],
    backgroundUrl: 'assets/idcards/service_dog_under_training.png',
  },
}

const USER_DATA_X_POS = 480
const USER_DATA_Y_POS = 320
const USER_DATA_Y_DELTA = 140
const USER_DATA_FONT_STYLE: Partial<PIXI.TextStyle> = {
  fontFamily: 'Arial',
  fontSize: 50,
  fontWeight: 'bold',
  fill: 0x000000,
  letterSpacing: 0,
}
const USER_NAME_FONT_STYLE: Partial<PIXI.TextStyle> = {
  ...USER_DATA_FONT_STYLE,
  fontSize: 50,
}


// background width 350
const ID_CARD_EXPIRY_TEXT_X = 120
const ID_CARD_EXPIRY_TEXT_Y = 850

const EXPIRY_FONT_STYLE: Partial<PIXI.TextStyle> = {
  fontFamily: 'Ariel',
  fontWeight: 'bold',
  fontSize: 65,
  fill: 0xffffff,
}

const ID_CARD_NUMBER_X = 1560
const ID_CARD_NUMBER_Y = 223

const DOG_PROFILE_IMG_X = 273
const DOG_PROFILE_IMG_Y = 530

@Component({
  templateUrl: 'idcard.component.html',
  styleUrls: ['idcard.component.scss'],
})
export class IdCardComponent implements OnInit, OnDestroy {
  dog: Dog

  trainerNameControl = new UntypedFormControl(null, Validators.required)
  idCardNumberControl = new UntypedFormControl(null, Validators.required)
  idCardExpiryControl = new UntypedFormControl(
    moment().add(1, 'year').format(EXPIRY_TIME_FORMAT),
    Validators.pattern(/^\d{4}\.\d{2}\.\d{2}\.?$/)
  )

  @ViewChild('container')
  container: ElementRef

  app: PIXI.Application
  dogDataAdded = false

  dogProfile: PIXI.Sprite
  trainerNameText: PIXI.Text
  idCardNumberText: PIXI.Text
  idCardExpiryText: PIXI.Text

  constructor(private storage: AngularFirestore, private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    this.app?.destroy()
  }

  idCardTypeChange(evt: MatButtonToggleChange) {
    const idCard = IDCARD_META[evt.value]
    if (!idCard) {
      alert('Valami félrement. Nincs adat ehhez a kártya típushoz')
      return
    }

    if (this.app) {
      this.app.destroy(true)
      this.trainerNameText = null
      this.idCardNumberText = null
      this.idCardExpiryText = null
    }

    setTimeout(async () => {
      this.dogDataAdded = false
      this.app = new PIXI.Application()
      await this.app.init({ width: idCard.size[0], height: idCard.size[1], backgroundColor: 0xffffff })
      var backgroundTexture = await PIXI.Assets.load(idCard.backgroundUrl)
      const bg = PIXI.Sprite.from(backgroundTexture)
      this.app.stage.addChild(bg)
      this.container.nativeElement.appendChild(this.app.canvas)
      this.addDogDataToScreen()
    })
  }

  ngOnInit(): void {
    this.trainerNameControl.valueChanges.subscribe({
      next: (name) => this.renderTrainerName(name),
    })
    this.idCardNumberControl.valueChanges.subscribe({
      next: (num) => this.renderIdCardNumber(num),
    })
    this.idCardExpiryControl.valueChanges.subscribe({
      next: (txt) => this.renderExpiry(txt),
    })

    this.route.paramMap
      .pipe(
        map((params) => params.get('dogId')),
        filter((x) => !!x),
        switchMap((dogId) => {
          return this.storage.collection<Dog>('dogs').doc<Dog>(dogId).get()
        })
      )
      .subscribe({
        next: (dogDoc) => {
          if (dogDoc.exists) {
            this.dog = mapFirebaseDog(dogDoc.data())
            console.log(this.dog)
            this.trainerNameControl.setValue(this.dog.owner.name)
            this.addDogDataToScreen()
          } else {
            console.log('Doc document not exists', dogDoc)
          }
        },
      })
  }

  private renderTrainerName(name: string) {
    if (name && this.app) {
      if (!this.trainerNameText) {
        this.trainerNameText = new PIXI.Text({
          text: name,
          style: USER_NAME_FONT_STYLE
        })
        // this.trainerNameText.anchor.set(0.5, 0)
        this.trainerNameText.x = USER_DATA_X_POS
        this.trainerNameText.y = USER_DATA_Y_POS
        this.app.stage.addChild(this.trainerNameText)
      } else {
        this.trainerNameText.text = name
      }
    }
  }

  private renderIdCardNumber(num: string) {
    if (num && this.app) {
      num = num.toUpperCase()
      if (!this.idCardNumberText) {
        this.idCardNumberText = new PIXI.Text({
          text: num,
          style: {
            fontFamily: 'Ariel',
            fontWeight: 'bolder',
            fontSize: 60,
            fill: 0xffffff,
            letterSpacing: -1,
          }
        })
        this.idCardNumberText.anchor.set(1, 1)
        this.idCardNumberText.x = ID_CARD_NUMBER_X
        this.idCardNumberText.y = ID_CARD_NUMBER_Y
        this.app.stage.addChild(this.idCardNumberText)
      } else {
        this.idCardNumberText.text = num
      }
    }
  }

  private renderExpiry(expiryText: string) {
    if (this.app && expiryText) {
      if (!this.idCardExpiryText) {
        this.idCardExpiryText = new PIXI.Text({
          text: expiryText, 
          // width: 330,
          style: EXPIRY_FONT_STYLE
        })
        this.idCardExpiryText.x = ID_CARD_EXPIRY_TEXT_X
        this.idCardExpiryText.y = ID_CARD_EXPIRY_TEXT_Y
        this.app.stage.addChild(this.idCardExpiryText)
      } else {
        this.idCardExpiryText.text = expiryText
      }
    }
  }

  private renderDogProfile() {
    if (this.app && this.dogProfile) {
      setTimeout(() => {
        this.app.stage.addChild(this.dogProfile)
      })
    }
  }

  mapDogSex(sex: DogSex): string {
    switch (sex) {
      case DogSex.IVAROS_KAN:
        return 'Ivaros kan'
      case DogSex.IVAROS_SZUKE:
        return 'Ivaros szuka'
      case DogSex.IVARTALAN_KAN:
        return 'Ivartalan kan'
      case DogSex.IVARTALAN_SZUKA:
        return 'Ivartalan szuka'
      default:
        return sex
    }
  }

  addDogDataToScreen() {
    // base Y is the starting position and delta is between texts
    const baseY = USER_DATA_Y_POS + USER_DATA_Y_DELTA

    if (!this.app || !this.dog || this.dogDataAdded) {
      return
    }

    if (!this.trainerNameText) {
      this.renderTrainerName(this.trainerNameControl.value)
    }

    if (!this.idCardExpiryText) {
      this.renderExpiry(this.idCardExpiryControl.value)
    }

    if (!this.idCardNumberText) {
      this.renderIdCardNumber(this.idCardNumberControl.value)
    }

    this.renderDogProfile()

    const dogName = new PIXI.Text({
      text: this.dog.name,
      style: USER_NAME_FONT_STYLE
    })
    dogName.x = USER_DATA_X_POS
    dogName.y = baseY

    const breed = new PIXI.Text({
      text: this.capitalize(this.dog.breed),
      style: USER_DATA_FONT_STYLE
    })
    breed.x = USER_DATA_X_POS
    breed.y = baseY + 1 * USER_DATA_Y_DELTA

    const chipNumber = new PIXI.Text({
      text: this.dog.chipNumber,
      style: USER_DATA_FONT_STYLE
    })
    chipNumber.x = USER_DATA_X_POS
    chipNumber.y = baseY + 2 * USER_DATA_Y_DELTA

    this.app.stage.addChild(dogName)
    this.app.stage.addChild(breed)
    this.app.stage.addChild(chipNumber)

    this.dogDataAdded = true
  }

  private capitalize(str: string) {
    if (str) {
      const firstLetter = str.charAt(0).toUpperCase()
      return str.length > 1 ? firstLetter + str.slice(1) : firstLetter
    }
    return undefined
  }

  async dogImageSelected(imageUrl: string) {
    const dogImageAsset = await PIXI.Assets.load({src: imageUrl, loadParser: 'loadTextures'})
    if (this.dogProfile) {
      this.dogProfile.destroy()
    }

    this.dogProfile = PIXI.Sprite.from(dogImageAsset)
    this.dogProfile.anchor.set(0.5)
    this.dogProfile.x = DOG_PROFILE_IMG_X
    this.dogProfile.y = DOG_PROFILE_IMG_Y

    this.renderDogProfile()
  }
}
