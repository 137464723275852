<div id="container">
  <div id="control-row">
    <button [disabled]="!entityId" mat-raised-button color="primary" (click)="addNewDocument()">Új dokumentum</button>
    <app-document-select
      [selectControl]="documentTypeFilter"
      [addEmptyElement]="true"
      [documentTypes]="documentTypes"
    ></app-document-select>
  </div>

  <div class="new-document-entry" *ngFor="let doc of newDocuments; trackBy: trackByItem">
    <app-document-entry
      [newEventId]="doc.newDocId"
      [errorMessage]="doc.errorMessage"
      [percentChange]="doc.percentChange"
      [documentTypes]="documentTypes"
      (upload)="upload($event)"
      (delete)="delete($event)"
    ></app-document-entry>
  </div>

  <mat-divider></mat-divider>

  <div class="document-entry" *ngFor="let doc of filteredDocuments">
    <app-document-entry [document]="doc" (delete)="delete($event)" [documentTypes]="documentTypes"></app-document-entry>
  </div>
</div>
