<h1>{{ title }}</h1>
<div id="container">
  <div id="dog-form-container">
    <div id="retired-banner" *ngIf="isDogRetired">
      <h3>Nyugdíjazott kutya</h3>
      <p>
        Nyugdíjazva: {{ originalDog.retiredAt | date: 'medium' }} <br />
        Nyugdíjazta: {{ originalDog.retiredBy }}
      </p>
    </div>
    <form [formGroup]="dogGroup" (ngSubmit)="submit()">
      <div id="form-inputs">
        <div>
          <h4>Kutya adatai</h4>
          <mat-form-field appearance="fill">
            <mat-label>Kutya neve</mat-label>
            <input matInput formControlName="name" />
            <mat-error>Kötelező mező</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Kutya születési éve</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthDate" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>Kötelező mező használd a naptár ikont.</mat-error>
            <mat-hint>Használd a kis naptár ikont</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Fajta</mat-label>
            <input matInput formControlName="breed" />
            <mat-error>Kötelező mező</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Neme</mat-label>
            <mat-select formControlName="dogSex">
              <mat-option *ngFor="let dogSex of dogSexs" [value]="dogSex.key">
                {{ dogSex.value }}
              </mat-option>
            </mat-select>
            <mat-error>Kötelező mező</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Munka típus {{ dogGroup.get('assistanceTypes')?.value.length }} kiválasztva</mat-label>
            <mat-select [formControl]="singleAssistanceType" [panelWidth]="null">
              <mat-option *ngFor="let at of assistanceTypes" [value]="at.key">
                {{ at.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="dogGroup.get('assistanceTypes').invalid">Legalább egy valamit dolgozzon az a kutya </mat-error>
          </mat-form-field>

          <ul id="assistance-type-list">
            <li *ngFor="let type of dogGroup.get('assistanceTypes').value">
              {{ getAssistanceTypeName(type) }}
              <a mat-button (click)="removeAssistanceTypeFromList(type)">
                <mat-icon>delete</mat-icon>
              </a>
            </li>
          </ul>

          <div id="organization-list">
            <span>Szervezet: </span>
            <mat-checkbox
              (change)="organizationCheckBox('MATESZE', $event)"
              [checked]="dogGroup.get('organizations').value.includes('MATESZE')"
            >
              MATESZE
            </mat-checkbox>
            <mat-checkbox (change)="organizationCheckBox('ADI', $event)" [checked]="dogGroup.get('organizations').value.includes('ADI')">
              ADI
            </mat-checkbox>
            <div *ngIf="dogGroup.get('organizations').invalid">
              <mat-error> Legalább az egyik szervezetet be kell jelölni. </mat-error>
            </div>
          </div>

          <mat-form-field appearance="fill">
            <mat-label>Chip szám</mat-label>
            <input #chipNumberInput matInput maxlength="15" formControlName="chipNumber" placeholder="15 számjegy" />
            <mat-error>15 számjegyből álló mező</mat-error>
            <mat-hint>{{ chipNumberInput.value?.length || 0 }}/15</mat-hint>
          </mat-form-field>
        </div>
        <div>
          <h4>Kiképző</h4>
          <app-trainer-select [parentFormGroup]="dogGroup" controlName="trainer"></app-trainer-select>

          <h4>Gazda adatai</h4>
          <ng-container formGroupName="owner">
            <mat-form-field appearance="fill">
              <mat-label>Tulajdonos neve</mat-label>
              <input matInput formControlName="name" />
              <mat-error>Egy gazda kötelező</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Lakcím</mat-label>
              <input matInput formControlName="address" />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Tulajdonos telefonszám</mat-label>
              <input matInput type="tel" formControlName="phone" />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Tulajdonos email</mat-label>
              <input matInput type="email" formControlName="email" />
              <mat-error>Helytelen email formátum</mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div id="action-bar" *ngIf="!isDogRetired">
        <mat-error *ngIf="saveError">{{ saveError }}</mat-error>

        <button [disabled]="isSaving || dogGroup.invalid" mat-raised-button color="primary" type="submit">Mentés</button>
        <button
          *ngIf="originalDog"
          [disabled]="isSaving || dogGroup.pristine"
          mat-raised-button
          color="warn"
          type="button"
          (click)="confirmResetDog()"
        >
          Visszaállít
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="originalDog" id="dog-meta">
    <p *ngIf="originalDog">
      Utoljára módosítva: {{ originalDog.createdAt | date: 'medium' }} <br />
      Utoljára módosította: {{ originalDog.createdBy }} <br />
      Kutya adatbázis azonosító: {{ originalDogId }}
    </p>
    <button mat-button color="warn" *ngIf="originalDog && originalDog.retiredAt == null" (click)="confirmRetireDog()">Nyugdíjazás</button>
    <button mat-button color="warn" *ngIf="originalDog && originalDog.retiredAt != null" (click)="confirmReInstateDog()">
      Munkába állít
    </button>
    <br />
    <button [disabled]="isSaving" mat-button color="warn" (click)="removeDog()">Törlés</button>
  </div>
</div>
